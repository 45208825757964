import React, { useState, useEffect } from "react";
import styles from "../styles/Yamauchi.module.css";
import { useWindowSize } from "react-use";

type Props = {
  imageURL: any;
  charaName: String;
  charaDiscription: String;
  charaHeight: Number;
};

const CharacterDiscription: React.VFC<Props> = ({
  imageURL,
  charaName,
  charaDiscription,
  charaHeight,
}: Props) => {
  return (
    <>
      {/* キャラ名と説明 */}

      <div className={styles.chara_detail}>
        <div className={styles.chara_name} style={{ textAlign: "center" }}>
          {charaName}
        </div>
        <p className="{styles.chara_profile}">{charaDiscription}</p>
      </div>
    </>
  );
};

export default CharacterDiscription;
