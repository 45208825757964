import "./App.css";
import ResponsiveFooter from "./components/views/ResponsiveFooter";
import Router from "./Router";
import { useWindowSize } from "react-use";

function App() {
  const { width } = useWindowSize();
  const styles1 = {
    container: {
      padding: "0px 20vw 0px 20vw",
    },
  };
  const styles2 = {
    container: {
      padding: "0 16px",
    },
  };
  return (
    <div>
      {width > 1100 ? (
        <>
          {" "}
          <div style={styles1.container}>
            <Router />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div style={styles2.container}>
            <Router />
          </div>
        </>
      )}

      <ResponsiveFooter />
    </div>
  );
}

export default App;
