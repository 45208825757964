import React, { useState, useEffect } from "react";
import styles from "../styles/Yamauchi.module.css";
import { useWindowSize } from "react-use";
import CharacterDiscription from "./CharaDiscription";
import CharacterImage from "./CharaImage";

type Props = {
  imageURL: any;
  charaName: String;
  charaDiscription: String;
  charaHeight: Number;
};

const Character: React.VFC<Props> = ({
  imageURL,
  charaName,
  charaDiscription,
  charaHeight,
}: Props) => {
  const { width } = useWindowSize();
  const isSmallScreen = width <= 1200; // 1200px以下なら縦レイアウト

  return (
    <>
      {" "}
      <div
        className={styles.chara_container}
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row", // 縦 or 横配置
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {isSmallScreen ? (
          <>
            {" "}
            <CharacterImage
              imageURL={imageURL}
              charaName={charaName}
              charaDiscription={charaDiscription}
              charaHeight={charaHeight}
            />
            {/* キャラ名と説明 */}
            <CharacterDiscription
              imageURL={imageURL}
              charaName={charaName}
              charaDiscription={charaDiscription}
              charaHeight={charaHeight}
            />
          </>
        ) : (
          <>
            <CharacterDiscription
              imageURL={imageURL}
              charaName={charaName}
              charaDiscription={charaDiscription}
              charaHeight={charaHeight}
            />
            <CharacterImage
              imageURL={imageURL}
              charaName={charaName}
              charaDiscription={charaDiscription}
              charaHeight={charaHeight}
            />

            {/* キャラ名と説明 */}
          </>
        )}
      </div>
    </>
  );
};

export default Character;
