import React, { useState, useEffect } from "react";
import styles from "../styles/Yamauchi.module.css";
import { useWindowSize } from "react-use";

type Props = {
  imageURL: any;
  charaName: String;
  charaDiscription: String;
  charaHeight: Number;
};

const CharacterImage: React.VFC<Props> = ({
  imageURL,
  charaName,
  charaDiscription,
  charaHeight,
}: Props) => {
  const baseHeight = 200; // cm (基準身長)
  const basePixel = 500; // px (基準の高さ)
  const calculatedHeight = (Number(charaHeight) / baseHeight) * basePixel; // 画像の高さを計算

  // 背景に横線を引く（10cmごとに線を表示）
  const lineSpacing = basePixel / (baseHeight / 10);
  const heightLines = Array.from({ length: baseHeight / 10 + 1 }, (_, i) => ({
    label: `${baseHeight - i * 10}cm`, // 200, 190, 180... 0
    position: basePixel - i * lineSpacing, // 上が200cm, 下が0cmになるように調整
  }));
  return (
    <>
      {/* キャラの表示 */}
      <div className={styles.chara_canvas}>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
            minHeight: `${basePixel}px`,
            backgroundImage: `repeating-linear-gradient(
            to bottom,
            transparent,
            transparent ${lineSpacing - 2}px,
            rgba(0, 0, 0, 0.5) ${lineSpacing}px
          )`,
          }}
        >
          {/* 身長目盛りとキャラ画像を同じコンテナ内に */}
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              position: "relative",
            }}
          >
            {/* 身長目盛り */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                position: "absolute",
                left: "5px",
                bottom: "0",
                fontSize: "14px",
                color: "black",
              }}
            >
              {heightLines.map((line) => (
                <div
                  key={line.position}
                  style={{
                    height: `${lineSpacing}px`,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {line.label}
                </div>
              ))}
            </div>

            {/* キャラクター画像 */}
            <img
              src={imageURL}
              // alt={charaName}
              style={{
                height: `${calculatedHeight}px`,
                width: "auto",
                display: "block",
                marginLeft: "60px",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CharacterImage;
